import React from "react";
import Cookies from 'js-cookie';
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import Home from './Home'

function Login() {

  return(
    <div>
      <Auth0Provider
        domain="nkipreos.auth0.com"
        clientId="8WKBBdDVH9lpcH99g2IZPIy6RvhuLt8n"
        redirectUri={window.location.origin}
      >
        <Home />
      </Auth0Provider>
    </div>
  );
}

export default Login;
