import React from "react";
import Button from '@mui/material/Button';
import { useAuth0 } from "@auth0/auth0-react";


export default function LogoutButton() {
  const { logout } = useAuth0();

  return <Button size="small" onClick={() => logout({ returnTo: window.location.origin })} >Salir</Button>
}
