import React from "react";
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styles from './index.module.css'
import { useAuth0 } from "@auth0/auth0-react";

export default function UnAuthenticated() {

  const { loginWithRedirect } = useAuth0();

  return (
    <div className={ styles.container }>
      <Box className={ styles.cardBox }>
        <Card sx={{ minWidth: 275, maxWidth: 450}}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Bienvenido
            </Typography>
            <Typography variant="h5" component="div">
              Credencial CPV
            </Typography>
            <Typography variant="body2">
              Bienvenido al nuevo sistema de credenciales para beneficios de centro de padres del Colegio Puerto Varas. Para obtener la credencial CPV es necesario que hagas login con tu mail @cpv.cl. Haz click en el siguiente botón para obtener tu credencial.
            </Typography>
          </CardContent>
          <CardActions sx={{justifyContent: 'center'}}>
            <Button variant="contained" size="large" onClick={() => loginWithRedirect()} >Obtener Credencial</Button>
          </CardActions>
        </Card>
      </Box>
    </div>
  );
}
