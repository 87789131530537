import React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LogoutButton from './LogoutButton'


class UserInfoCard extends React.Component {

  render() {
    return(
      <React.Fragment>
        <Card variant="outlined">
          <CardContent>
            <CardMedia
              component="img"
              height="194"
              image="/static/images/cards/banner.png"
              alt="Paella dish"
            />
            <Avatar alt={ this.props.name } src={ this.props.picture_url } />
            <Typography variant="h5" component="div">
              Credencial CPV
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              { this.props.name }
            </Typography>
            <Typography variant="body2">
              Bienvenidos a la credencial digital de convenios del Colegio Puerto Varas. Presenta esta credencial en los lugares con convenio y obtén tu beneficio.
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" href="https://www.centrodepadrescpv.cl/convenios">Convenios</Button>
            <LogoutButton />
          </CardActions>
        </Card>
      </React.Fragment>
    )
  }
}

export default UserInfoCard;
