import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Box from '@mui/material/Box';
import UserInfoCard from './UserInfoCard'
import styles from './index.module.css'

export default function Authenticated() {

  const { user, logout } = useAuth0();

  if(user.email.split('@').at(-1) != "cpv.cl") {
    logout({ returnTo: window.location.origin });
  }

  return (
    <div className={ styles.container }>
      <Box className={ styles.cardBox }>
        <UserInfoCard
          name={ user.name }
          picture_url={ user.picture }
        />
      </Box>
    </div>
  )
}
