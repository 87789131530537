import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Authenticated from "./Authenticated/index"
import UnAuthenticated from "./UnAuthenticated/index"

export default function Home() {
  const { loginWithRedirect,
          isAuthenticated,
          user,
          isLoading } = useAuth0();

  if(!isAuthenticated && isLoading){
    return <div>Loading...</div>
  }

  if(isAuthenticated) {
    return <Authenticated />;
  } else {
    return <UnAuthenticated />; 
  }
      
};


